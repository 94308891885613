html, body {
  background-color: #fff !important;
}

.text-outline {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.border-rounded-left::before {
  @apply bg-pastel-blue;
  content: "";
  height: 140%;
  width: 13px;
  border-radius: 999px;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  min-height: 100px;
}

.border-left-pastel-blue::before,
.border-left-light-pink::before,
.border-left-dark-pink::before {
  content: "";
  width: 15px;
  height: 100%;
  border-radius: 999px;
  position: absolute;
  top: 0;
  left: 0;
}

.border-left-pastel-blue::before {
  @apply bg-pastel-blue;
}

.border-left-light-pink::before {
  @apply bg-light-pink;
}

.border-left-dark-pink::before {
  @apply bg-dark-pink;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-pastel-blue;
}

::-webkit-scrollbar-thumb {
  @apply rounded-full bg-dark-blue hover:bg-custom-blue;
}
