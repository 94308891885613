@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  position: relative;
  min-height: calc(97vh - 100px);
  padding-bottom: 72px;
}

@media screen and (max-width: 767px) {
  #root {
    padding-bottom: 270px;
  }
}