header {
  box-shadow: 0px 4px 80px rgba(56, 90, 133, 0.13);
}

.menu-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.menu-btn-burger {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: #252525;
}

.menu-btn-burger:nth-child(2) {
  width: 65%;
}

.nav-menu-mobile {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: right 500ms ease;
  box-shadow: -10px 0 10px rgba(0, 0, 0, .1);
}

.nav-menu-mobile-open {
  right: 0;
}

.nav-menu-mobile > ul > li > a {
  @apply block p-5 rounded-l-full cursor-pointer duration-300 hover:shadow-xl;
}
