.cgu-title {
    @apply text-lg font-bold md:text-2xl mt-10 md:mt-16 mb-2 sm:mb-5;
}

.cookies-table,
.cookies-table tr,
.cookies-table td {
    border: 1px solid #000;
    background-color: #fff !important;
}

.cookies-table .highlight {
    background-color: #8AB1AB !important;
}

.cookies-table td {
    padding: 10px;
}