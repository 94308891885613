.carousel {
  position: relative;
  width: 80%;
  max-width: 450px;
  height: 300px;
  perspective: 600px;
  transform-style: preserve-3d;
}

.card-container {
  @apply absolute w-full h-full duration-300;
  transform: 
    rotateY(calc(var(--offset) * 50deg)) 
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 0.5rem));
}

.card {
  @apply w-full h-full rounded-xl duration-300 p-6 bg-pastel-blue;
}

.card h2 {
  @apply text-lg font-bold mb-3;
}

.card p {
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card p, .card h2 {
  @apply duration-300;
  opacity: var(--active);
}

.nav {
  @apply absolute flex items-center justify-center top-1/2 z-10 cursor-pointer text-custom-blue;
  font-size: 1.5rem;
  user-select: none;
  background: unset;
  border: unset;
}

.nav.left {
  transform: translateX(-100%) translatey(-50%);
}

.nav.right {
  right: 0;
  transform: translateX(100%) translatey(-50%);
}
